<template>
    <InputLayout :v="v" :label="label">
        <input 
            class="form-control" 
            :type="type" 
            :min="min" 
            :max="max" 
            :id="v.$path" 
            :class="{ 'is-invalid': v && v.$error }" 
            :value="modelValue" 
            @input="$emit('update:modelValue', $event.target.value)"
            @focus="$emit('onClick')"
            @change="$emit('onChange', $event)"
            v-on="isPreventSubmit ? { keydown: preventSubmit } : {}"
        />
    </InputLayout>
</template>

<script>
    import InputLayout from '@/components/InputLayout.vue';

    export default {
        props: {
            label: {
                required: true
            },
            v: {
                required: true,
            },
            modelValue: {
                required: true,
            },
            type: {
                default: 'text',
            },
            min: {
                default: '',
            },
            max: {
                default: '',
            },
            isPreventSubmit: {
                default: false,
            },
        },
        components: {            
            InputLayout,
        },
        emits: ['update:modelValue','onClick','onChange'],
        methods: {
            preventSubmit: function(event)
            {
                if(event.key === 'Enter')
                {
                    event.stopPropagation()
                    event.preventDefault();
                }
            }
        }
    }
</script>
