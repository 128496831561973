<template>
    <div class="bg-white">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <div>
                <h1 class="h4 mb-0">Invitations</h1>
            </div>
            <router-link class="btn btn-primary" to="/invite">Invite a user</router-link>
        </div>
        <br>
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <router-link :to="{ name: 'Users' }" class="nav-link">Users</router-link>
            </li>
            <li class="nav-item">
                <router-link :to="{ name: 'Invitations' }" class="nav-link active">Invitations</router-link>
            </li>
        </ul>
        <br>
        <ul v-if="invitationsData && invitationsData.data.length" class="order-list mb-4">
            <li class="header-row">
                <div class="row align-items-center">
                    <div class="col">
                        <strong>Email</strong>
                    </div>
                    <div class="col d-flex justify-content-end align-items-center"></div>
                </div>
            </li>
            <li v-for="invitation of invitationsData.data" :key="invitation.id">
                <div class="row align-items-center">
                    <div class="col">
                        <div class="">
                            <strong>{{invitation.email}}</strong>
                        </div>
                    </div>
                    <div class="col d-flex justify-content-end align-items-center">
                        <div class="text-center"><router-link class="btn btn-secondary" :to="{ name: 'Invitation', params: { id: invitation.id }}">View</router-link></div>
                    </div>
                </div>
            </li>
        </ul>

        <div v-if="invitationsData && !invitationsData.data.length">
            No Invitations Sent
        </div>
        <Pagination
            v-if="invitationsData && invitationsData.data.length"
            v-bind:currentPage="currentPage"
            v-bind:lastPage="lastPage"
            viewComponent="Invitations"
        />
    </div>
</template>
<style scoped lang="scss">
.order-list
{
    list-style: none;
    padding: 0px;
    margin: 0px;

    > li
    {
        border-top: 1px solid #EEEEEE;
        padding: 10px 0px;

        strong
        {
            color: $swift-dark-grey;
        }
    }

    .header-row
    {
        border-top: 0px;
    }
}
</style>

<script>
import api from "@/services/api";
import Pagination from '@/components/Pagination.vue';
import router from "@/router";

export default {
    data() {
        return {
            invitationsData: null,
            email: '',
        }
    },
    components: {
        Pagination,
    },
    computed: {
        currentPage () {
            var pageParam = this.$route.query.page;
            return typeof pageParam === 'undefined' ? 1 : parseInt(pageParam);
        },
        lastPage () {
            if(this.invitationsData !== null)
            {
                return parseInt(this.invitationsData.meta.last_page);
            }

            return null;
        },
    },
    methods: {
        moveUp() {
            const html = document.querySelector('html')
            var scrollBehavior = html.style.scrollBehavior;
            html.style.scrollBehavior = 'auto'
            window.scrollTo({
                top: 0,
            });
            setTimeout(() => {
                html.style.scrollBehavior = scrollBehavior;
            }, 100);
        },
        fetchInvitations: function() {
            if(!this.$store.state.currentPracticeId)
            {
                this.invitationsData = null;
                return;
            }

            this.moveUp();

            var params = {
                practice_id: this.$store.state.currentPracticeId,
                page: this.currentPage,
            };

            api
                .get('/api/v1/userAdmin/invitations', {
                    params: params,
                })
                .then((response) => {
                    this.invitationsData = response.data;
                })
                .catch(() => {
                    console.log('Catch invites error');
                    router.push({ name: 'Dashboard' });
                });
        },
    },
    mounted () {
        this.fetchInvitations();
    },
    watch: {
        currentPage() {
            this.fetchInvitations();
        },
        '$store.state.currentPracticeId': function() {
            this.fetchInvitations();
        }
    },
}

</script>
