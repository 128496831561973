<template>
     <div class="d-flex justify-content-between align-items-center mb-3">
        <div>
            <h1 class="h4 mb-0">Billing</h1>
        </div>
    </div>
    <div class="row">
        <SubsectionLink :to="{ name: 'Accounts'}" heading="Accounts" description="View invoices, credits and statements for your accounts" />
        <SubsectionLink :to="{ name: 'SwiftPlus'}" heading="SwiftPlus" description="Current and historic monthly statements for SwiftPlus" />        
    </div>
</template>

<style scoped lang="scss">

</style>

<script>
    import SubsectionLink from '@/components/SubsectionLink.vue';

    export default {
        mounted () {
                       
        },
        components: {
            SubsectionLink,
        }
    }

</script>