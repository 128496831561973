<template>
    <InputLayout :v="v" :label="label">
        <select
            class="form-select" 
            :id="v.$path" 
            :class="{ 'is-invalid': v && v.$error }" 
            :value="modelValue" 
            @input="$emit('update:modelValue', $event.target.value)"  
        >
            <option v-if="!removeEmptyOption"></option>
            <option v-for="option of options" v-bind:key="option.id" :value="option.id" :disabled="option.enabled !== undefined && option.enabled != true">{{option.label}}</option>
        </select>
    </InputLayout>
</template>

<script>
    import InputLayout from '@/components/InputLayout.vue';

    export default {
        props: {
            label: {
                required: true
            },
            v: {
                required: true,
            },
            modelValue: {
                required: true,
            },
            options: {
                required: true,
            },
            removeEmptyOption: {
              default: false
            },
        },
        components: {            
            InputLayout,
        },
        emits: ['update:modelValue'],
    }
</script>
