<template>
    <div class="bg-white">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <div>
                <h1 class="h4 mb-0">Invite a user to Swift Connect</h1>
            </div>
        </div>
        <div class="alert alert-warning" v-if="alertMessage">
            {{alertMessage}}
        </div>
        <form enctype="multipart/form-data" novalidate class="bg-white" @submit.prevent="submitInvite">
            <div>
                <div class="row">
                    <div class="col-12 col-md-6 mb-3">
                        <TextInput
                            label="Email address"
                            v-model="v$.email.$model"
                            :v="v$.email"
                            type="email"
                        />
                    </div>
                </div>
                <div class="row" v-if="userType === 'practice'">
                    <label class="mb-3">Permission Type</label>
                    <div class="col-12 col-md-6 mb-3 radio-buttons">
                        <input type="radio" class="btn-check" name="permissionType" id="typePractice" autocomplete="off"  @click="permissionType = 'practice'">
                        <label class="btn btn-primary select" for="typePractice">Practice</label>
                        <input type="radio" class="btn-check" name="permissionType" id="typeDentist" autocomplete="off" @click="permissionType = 'dentist'">
                        <label class="btn btn-primary select" for="typeDentist">Dentist</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6 mb-3">
                        <div v-if="permissionType === 'practice'">
                            <strong>Practice User: </strong>
                            View the details of all dentists at this practice.
                        </div>
                        <div v-if="permissionType === 'dentist'">
                            <strong>Dentist User: </strong>
                            View the details of an individual dentist.
                        </div>
                    </div>
                </div>
                <div class="row" v-if="permissionType === 'dentist' || userType === 'dentist'">
                    <div class="col-12 col-md-6 mb-3">
                        <DropdownInput
                            :options="selectableDentists"
                            v-model="v$.selectedDentistId.$model"
                            :v="v$.selectedDentistId"
                            label="Dentist"
                        />
                    </div>
                </div>
                <div class="row">
                    <label class="mb-3">Access Level</label>
                    <div class="col-12 col-md-6 mb-3 radio-buttons">
                        <input type="radio" class="btn-check" name="accessLevel" id="permissionBasic" autocomplete="off" @click="level = this.$accessLevel.basic">
                        <label class="btn btn-primary select" for="permissionBasic">Basic</label>
                        <input type="radio" class="btn-check" name="accessLevel" id="permissionSensitive" autocomplete="off" @click="level = this.$accessLevel.sensitive">
                        <label class="btn btn-primary select" for="permissionSensitive">Sensitive</label>
                        <input type="radio" class="btn-check" name="accessLevel" id="permissionFull" autocomplete="off" @click="level = this.$accessLevel.full">
                        <label class="btn btn-primary select" for="permissionFull">Full</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6 mb-3">
                        <div class="access-level-text">
                            <div v-if="level === this.$accessLevel.basic">
                                <strong>Basic: </strong>
                                View order details & book collections. <br>
                            </div>
                            <div v-if="level === this.$accessLevel.sensitive">
                                <strong>Sensitive: </strong>
                                “Basic” access level, plus viewing sensitive information such as invoices and statements. <br>
                            </div>
                            <div v-if="level === this.$accessLevel.full">
                                <strong>Full: </strong>
                                “Sensitive” access level, plus the option to invite new users.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-4 mt-3">
                        <button class="btn btn-primary me-1" type="submit">Submit</button>
                        <router-link to="Invitations" class="btn btn-secondary">Cancel</router-link>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<style scoped lang="scss">
.radio-buttons {
    display: flex;
    justify-content: space-between;
    gap: 5%;
}
.select {
    flex-basis: 100%;
}
label.select:hover {
    background-color: $swift-fresh-green;
    color: $swift-not-black;
    border-color: $swift-not-black;
}
label.select {
    border-color: $swift-dark-grey;
    color: $swift-not-black;
    background-color: transparent;
}
</style>

<script>
import { ref } from 'vue';
import TextInput from '@/components/TextInput.vue';
import useVuelidate from '@vuelidate/core'
import {email, required, requiredIf} from '@vuelidate/validators'
import DropdownInput from "@/components/DropdownInput.vue";
import api from "@/services/api";
import router from "@/router";

export default {
    data() {
        return {
            userType: '',
            email: '',
            level: '',
            permissionType: '',
            selectedDentistId: '',
            selectableDentists: null,
            alertMessage: '',
        }
    },
    components: {
        DropdownInput,
        TextInput,
    },
    validations () {
        return {
            email: { required, email: email },
            level: { required },
            selectedDentistId: { requiredIf: requiredIf(this.permissionType === 'dentist') },
            permissionType: { required, },
        }
    },
    setup () {
        const externalResults = ref({});
        return {
            externalResults,
            v$: useVuelidate({ $externalResults: externalResults }),
        }
    },
    methods: {
        getUserType: function() {
            api
                .get('/api/v1/userAdmin/userType', {
                    params: {practice_id: this.$store.state.currentPracticeId}
                })
                .then((response) => {
                    this.userType = response.data;
                })
                .catch(() => {
                    console.log('Catch user type error');
                    router.push({ name: 'Dashboard' });
                });
        },
        fetchDentists: function() {
            api
                .get('/api/v1/userAdmin/dentists', {
                    params: {practice_id: this.$store.state.currentPracticeId}
                })
                .then((response) => {
                    this.selectableDentists = response.data;
                })
                .catch(() => {
                    console.log('Catch dentists error');
                });
        },
        submitInvite: function() {
            this.alertMessage = '';

            var data = {
                practice_id: this.$store.state.currentPracticeId,
            }

            if(this.email.trim().length)
            {
                data.email = this.email;
            }

            if(this.level)
            {
                data.level = this.level;
            }

            if((this.permissionType === 'dentist' || this.userType === 'dentist') && this.selectedDentistId.trim().length)
            {
                data.selected_dentist_id = this.selectedDentistId;
            }

            if (this.v$.$validate()) {
                api
                    .post('/api/v1/userAdmin/invite', data)
                    .then((response) => {
                        if (response.data.userId) {
                            router.push({ name: 'User', params: { id: response.data.userId } });
                        }
                        if (response.data.invitationId) {
                            router.push({ name: 'Invitation', params: { id: response.data.invitationId } });
                        }
                        if (!response.data.emailSent) {
                            this.alertMessage = 'Email could not be sent please try again later';
                        }
                    })
                    .catch(() => {
                        console.log('Catch invite error');
                    });
            }

        }
    },
    mounted () {
        this.getUserType();
        this.fetchDentists();
    },
}
</script>