<template>
    <div class="bg-white">
        <div class="d-flex justify-content-between align-items-center mb-3">            
            <div>
                <h1 class="h4 mb-0">{{ username }}'s permissions</h1>                
            </div>
            <router-link class="btn btn-primary" :to="{ name: 'Users' }">Back to Users</router-link>
        </div>
        <br>
        <ul class="order-list mb-4">
            <li class="practice-row header-row" v-if="practicePermissionData">
                <div class="row align-items-center">
                    <div class="col">
                        <strong>Practice Name</strong>
                    </div>
                    <div class="col">
                        <strong>Permission Level</strong>
                    </div>
                    <div class="col d-flex justify-content-end align-items-center" v-if="this.$store.state.user.id != this.id"></div>
                </div>
            </li>
            <li class="practice-info" v-if="practicePermissionData">
                <div class="row align-items-center">
                    <div class="col">
                        {{ practicePermissionData.practice_name }}
                    </div>
                    <div class="col">
                        {{ practicePermissionData.permission_level }} &nbsp;
                    </div>
                    <div class="col d-flex justify-content-end align-items-center" v-if="this.$store.state.user.id != this.id">
                        <div class="text-center">
                            <button class="btn btn-danger btn-sm" @click="revokePermission(practicePermissionData.id)">
                                <i class="bi bi-trash text-white"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </li>
            <li class="header-row" v-if="dentistsPermissionsData && dentistsPermissionsData.length">
                <div class="row align-items-center">
                    <div class="col">
                        <strong>Dentist Name</strong>
                    </div>
                    <div class="col">
                        <strong>Permission Level</strong>
                    </div>
                    <div class="col d-flex justify-content-end align-items-center" v-if="this.$store.state.user.id != this.id"></div>
                </div>
            </li>
            <li v-for="dentistPermission of dentistsPermissionsData" :key="dentistPermission.id">
                <div class="row align-items-center">
                    <div class="col">
                        {{dentistPermission.dentist_name}}
                    </div>
                    <div class="col">
                        {{dentistPermission.permission_level}}
                    </div>
                    <div class="col d-flex justify-content-end align-items-center" v-if="this.$store.state.user.id != this.id">
                        <button class="btn btn-danger btn-sm" @click="revokePermission(dentistPermission.id)">
                            <i class="bi bi-trash text-white"></i>
                        </button>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<style scoped lang="scss">
.order-list
{
    list-style: none;
    padding: 0px;
    margin: 0px;

    > li
    {
        border-top: 1px solid #EEEEEE;
        padding: 10px 0px;

        strong
        {
            color: $swift-dark-grey;
        }
    }

    .header-row
    {
        border-top: 0px;
    }
}
</style>

<script>
import api from "@/services/api";
import router from "@/router";

export default {
    props: {
        id: {
            required: true,
        },
    },
    data() {
        return {
            dentistsPermissionsData: null,
            practicePermissionData: null,
            username: '',
        }
    },
    methods: {
        moveUp() {
            const html = document.querySelector('html')
            var scrollBehavior = html.style.scrollBehavior;
            html.style.scrollBehavior = 'auto'
            window.scrollTo({
                top: 0,
            });
            setTimeout(() => {
                html.style.scrollBehavior = scrollBehavior;
            }, 100);
        },
        fetchUserPermissionsData: function() {
            if(!this.$store.state.currentPracticeId)
            {
                this.dentistsPermissionsData = null;
                this.practicePermissionData = null;
                return;
            }

            this.moveUp();

            var params = {
                practice_id: this.$store.state.currentPracticeId,
            };

            api
                .get('/api/v1/userAdmin/user/' + this.id, {
                    params: params
                })
                .then((response) => {
                    this.username = response.data.username;
                    this.practicePermissionData = response.data.practicePermission;
                    this.dentistsPermissionsData = response.data.dentistPermissions;
                })
                .catch(() => {
                    console.log('Catch dentist permission error');
                    router.push({ name: 'Dashboard' });
                });
        },
        revokePermission: function(userPermissionId) {
            var params = {
                practice_id: this.$store.state.currentPracticeId,
            };

            if (!confirm('Are you sure you want to remove this permission')) {
                return;
            }

            api
                .get('/api/v1/userAdmin/revoke/' + userPermissionId, {
                    params: params
                })
                .then((response) => {
                    if (response.data.permissionsLeft == 0) {
                        router.push({ name: 'Users' })
                    } else {
                        this.fetchUserPermissionsData();
                    }
                })
                .catch(() => {
                    console.log('Catch user permissions error');
                });
        },
    },
    mounted () {
        this.fetchUserPermissionsData();
    },
    watch: {
        '$store.state.currentDentistId': function() {
            this.fetchUserPermissionsData();
        }
    },
}

</script>
