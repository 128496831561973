<template>
    <router-link :to="to" class="subsection-link col-md-6 mb-4 pe-4">
        <div class="text">
            <div><strong>{{heading}}</strong></div>
            <div class="text-description">{{description}}</div>
        </div>
        <div class="arrow"><img src="@/assets/images/arrow.png" /></div>
    </router-link>
</template>

<script>
    export default {
        props: {
            to: {
                required: true,
            },
            heading: {
                required: true,
            },
            description: {
                required: true,
            },
        },
    }
</script>

<style scoped lang="scss">
    .subsection-link
    {
        text-decoration: none;
        display: flex;

        .text
        {
            flex-grow: 1;
            margin-right: $spacer;
        }

        .text-description
        {
            line-height: 1.3em;
        }

        .arrow
        {
            img
            {
                width: 30px;
                margin-top: 10px;
            }            
        }

        &:hover
        {
            .icon
            {
                background: $swift-light-grey;
                transition: 0.5s;
            }
        }
    }
</style>