<template>
    <label :for="v.$path" class="form-label">{{ label }}</label>
    <slot></slot>
    <span class="invalid-feedback" v-if="v && v.$error">{{v.$errors[0].$message}}</span>
</template>


<script>
    export default {
        props: {
            label: {
                required: true
            },
            v: {
                required: true,
            },
        },
    }
</script>

