<template>
    <div class="bg-white">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <div>
                <h1 class="h4 mb-0">Users</h1>
            </div>
            <router-link class="btn btn-primary" to="/invite">Invite a user</router-link>
        </div>
        <br>
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <router-link :to="{ name: 'Users' }" class="nav-link active">Users</router-link>
            </li>
            <li class="nav-item">
                <router-link :to="{ name: 'Invitations' }" class="nav-link">Invitations</router-link>
            </li>
        </ul>
        <br>
        <ul v-if="usersData && usersData.data.length" class="order-list mb-4">
            <li class="header-row">
                <div class="row align-items-center">
                    <div class="col">
                        <strong>Name</strong>
                    </div>
                    <div class="col d-flex justify-content-end align-items-center"></div>
                </div>
            </li>
            <li v-for="user of usersData.data" :key="user.id">
                <div class="row align-items-center">
                    <div class="col">
                        <div class="">
                            <strong>{{user.first_name}} {{user.last_name}}</strong> &nbsp;
                            <span class="badge bg-success" v-if="this.$store.state.user.id === user.id">You!</span>
                        </div>
                    </div>
                    <div class="col d-flex justify-content-end align-items-center">
                        <div class="text-center"><router-link class="btn btn-secondary" :to="{ name: 'User', params: { id: user.id }}">View</router-link></div>
                    </div>
                </div>
            </li>
        </ul>

        <div v-if="usersData && !usersData.data.length">
            No Users
        </div>
        <Pagination
            v-if="usersData && usersData.data.length"
            v-bind:currentPage="currentPage"
            v-bind:lastPage="lastPage"
            viewComponent="Users"
        />
    </div>
</template>

<style scoped lang="scss">
.order-list
{
    list-style: none;
    padding: 0px;
    margin: 0px;

    > li
    {
        border-top: 1px solid #EEEEEE;
        padding: 10px 0px;

        strong
        {
            color: $swift-dark-grey;
        }
    }

    .header-row
    {
        border-top: 0px;
    }
}
</style>

<script>
import api from "@/services/api";
import Pagination from '@/components/Pagination.vue';
import router from "@/router";

export default {
    data() {
        return {
            usersData: null,
            first_name: '',
            last_name: '',
        }
    },
    components: {
        Pagination,
    },
    computed: {
        currentPage () {
            var pageParam = this.$route.query.page;
            return typeof pageParam === 'undefined' ? 1 : parseInt(pageParam);
        },
        lastPage () {
            if(this.usersData !== null)
            {
                return parseInt(this.usersData.meta.last_page);
            }

            return null;
        },
    },
    methods: {
        moveUp() {
            const html = document.querySelector('html')
            var scrollBehavior = html.style.scrollBehavior;
            html.style.scrollBehavior = 'auto'
            window.scrollTo({
                top: 0,
            });
            setTimeout(() => {
                html.style.scrollBehavior = scrollBehavior;
            }, 100);
        },
        fetchUsers: function() {
            if(!this.$store.state.currentPracticeId)
            {
                this.usersData = null;
                return;
            }

            this.moveUp();

            var params = {
                practice_id: this.$store.state.currentPracticeId,
                page: this.currentPage,
            };

            if(this.first_name.trim().length)
            {
                params.first_name = this.first_name;
            }

            if(this.last_name.trim().length)
            {
                params.last_name = this.last_name;
            }

            api
                .get('/api/v1/userAdmin/users', {
                    params: params
                })
                .then((response) => {
                    this.usersData = response.data;
                })
                .catch(() => {
                    console.log('Catch users error');
                    router.push({ name: 'Dashboard' });
                });
        },
    },
    mounted () {
        this.fetchUsers();
    },
    watch: {
        currentPage() {
            this.fetchUsers();
        },
        '$store.state.currentPracticeId': function() {
            this.fetchUsers();
        }
    },
}

</script>
